<template>
  <div class="card">
    <div class="card-header card-header-with-toggle-btn">
      <div class="card-title">
        Pending Orders
        <small v-if="state.morePendingOrderCount" class="text-muted">
          (+{{ state.morePendingOrderCount }})
        </small>
      </div>
      <div class="d-flex align-items-center">
        <span class="me-2 fs13 hidden-sm-and-down">Show All Orders</span>
        <i
          class="icon-resize-full-screen full-screen-btn"
          @click="state.showLatestOrderPopUp = !state.showLatestOrderPopUp"
        />
      </div>
    </div>
    <div class="card-body">
      <NoOrder
        v-if="!state.orders"
        text="You have served all Orders."
        title="no order in queue"
        icon="icon-assignment_turned_in text-grey"
      />

      <NewOrdersList
        :orders="state.orders"
        no-orders-title="No Pending Orders Available."
      />

    </div>
  </div>

  <LatestOrdersPopUp
    v-model="state.showLatestOrderPopUp"
  />

</template>

<script>
import NoOrder from '@/components/Util/card/noOrder';
import { currency, dateFormat, getBadgeClass } from '@/Mixins/appHelper';
import { computed, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import LatestOrdersPopUp from '@/components/Dashboard/Order/LatestOrdersPopUp';
import NewOrdersList from '@/components/Dashboard/Order/NewOrdersList';
import { orderStatuses } from '@/utils/Helper';
import { collect } from 'collect.js';

export default {
  name: 'LatestPendingOrders',
  components: { NewOrdersList, LatestOrdersPopUp, NoOrder },
  emits: ['orderUpdated', 'update:showPopup'],
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {
    const store = useStore();

    const state = reactive({
      orderPrintInProgress: false,
      showLatestOrderPopUp: false,

      morePendingOrderCount: 0,
      orders: [],
      orderStatuses: [],
      reservationStatuses: [],
    });

    const openListAutomatically = computed(() => store.getters['setting/getSettings']('show_incomplete_orders_list_automatically'));

    const updateLocalState = () => {

      const allPendingOrders =
        computed(() => {
          const allIncompleteOrders = store.getters['dashboard/orders'];

          // only pending orders
          return allIncompleteOrders.filter(order => order.status === orderStatuses.pending);
        });

      const pendingOrdersToDisplay = computed(() => {
        return collect(allPendingOrders.value).take(10).toArray();
      });

      state.morePendingOrderCount = (allPendingOrders.value.length - pendingOrdersToDisplay.value.length);
      state.orders = pendingOrdersToDisplay;
      state.orderStatuses = computed(() => store.getters['dashboard/orderStatuses']);

      // state.loading = computed(() => store.state.dashboard.loading);
      // state.stats = computed(() => store.getters['dashboard/stats']);
      // state.reservationStatuses = computed(() => store.getters['dashboard/reservationStatuses']);
      // state.reservations = computed(() => store.getters['dashboard/reservations']);
    };

    watch(() => props.showPopup, (nv) => {
      state.showLatestOrderPopUp = nv;
    });

    watch(() => state.showLatestOrderPopUp, (nv) => {
      emit('update:showPopup', nv);
    });

    onMounted(() => {
      updateLocalState();

      setTimeout(() => {
        if (openListAutomatically.value) {
          state.showLatestOrderPopUp = true;
        }
      }, 1000);
    });

    return {
      state,
      currency,
      dateFormat,
      getBadgeClass,
    };
  }
};
</script>

<style lang="scss" scoped>
.full-screen-btn {
  font-size: 30px !important;
  padding: 5px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  cursor: pointer;
}
</style>
