<template>
  <div
    v-if="reservations.length"
    class="reservation-item"
  >
    <div class="reservation-container">
      <div class="reservation-header">
        <div class="content">Name</div>
        <div class="content requested-time">Requested For</div>
        <div class="content status">Persons</div>
        <div class="content">Status</div>
      </div>

      <StackedAnimation
        stack-duration="1.5"
        direction-in="left"
        direction-out="back"
      >
        <div
          v-for="(reservation, index) in reservations"
          :key="'reserve'+index"
          :class="`reservation-contents bg-light ${reservation.id === state.selectedReservation?.id ? 'selected-bg':''}`"
          @click="handleDetailsView(reservation)"
        >

          <div class="content customer">
            <a
              :href="`tel:${reservation.phone}`"
              @click.stop="() => {}"
            >
              <div class="name">{{ reservation.name }}</div>
              <div class="phone">
                <i class="icon-phone-call" />
                {{ reservation.phone }}
              </div>
            </a>
          </div>
          <div class="content requested-for">
            <div class="request">{{ dateFormat(reservation.datetime) }}</div>
          </div>
          <div class="content person">
            <div class="reservation-person">{{ reservation.person }}</div>
          </div>
          <div class="content status">
            <span
              class="btn btn-dark text-uppercase cursor-pointer fs15 p-2"
              @click.stop="updateReservation(reservation)"
            >
              {{ reservation.status }}
            </span>
          </div>

        </div>
      </StackedAnimation>
    </div>

    <ReservationAction
      :show-data="state.showDetails"
      :data="state.selectedReservation"
      @close="state.showDetails = !state.showDetails"
    />

    <StatusUpdateAction
      v-if="state.showStatusAction"
      :show-action="state.showStatusAction"
      :options="state.statusOptions"
      :current-option="state.selectedReservation?.status"
      :current-item="state.selectedReservation"
      action-type="reservation"
      :title="state.statusActionTitle"
      @cancelled="handleStatusUpdateAction(false)"
      @updated="handleStatusUpdateAction"
    />
  </div>

  <div
    v-else
    class="d-flex justify-content-center align-items-center"
  >
    <div>
      <div class="h3">{{ noReservationsTitle }}</div>
      <div class="h5">{{ noReservationsSubTitle }}</div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/Util/UserAvatar';
import { dateFormat, getBadgeClass } from '@/Mixins/appHelper';
import { computed, reactive } from 'vue';
import StatusUpdateAction from '@/components/Util/StatusUpdateAction';
import { useStore } from 'vuex';
import StackedAnimation from '@/components/Util/Animations/StackedAnimation';
import ReservationAction from '@/components/Reservation/ReservationAction';

export default {
  name: 'NewReservationsList',
  emits: ['reservationUpdated'],
  components: { ReservationAction, StackedAnimation, StatusUpdateAction, UserAvatar },
  props: {
    reservations: {
      type: Array,
      required: true
    },
    noReservationsTitle: {
      type: String,
      default: 'No New Reservations Available.'
    },
    noReservationsSubTitle: {
      type: String,
      default: 'Once there\'s new reservation, will show-up here'
    }
  },

  setup (props, { emit }) {

    const store = useStore();

    const state = reactive({
      statusActionTitle: 'Update Reservation Status',
      reservationOriginalStatuses: computed(() => store.getters['dashboard/reservationStatuses']),
      statusOptions: [],
      showStatusAction: false,

      showDetails: false,

      selectedReservation: {},
    });

    function handleUpdate () {
      state.statusActionTitle = 'Update Reservation Status';
      state.showStatusAction = true;

      // reset new orders
      store.dispatch('app/resetNewReservationCount');
    }

    const handleStatusUpdateAction = (updatedReservation = false) => {

      if (updatedReservation) {
        emit('reservationUpdated', updatedReservation);
      }

      state.showStatusAction = !state.showStatusAction;
    };

    const updateReservation = (item) => {

      if (!item) return;

      state.selectedReservation = item;

      handleUpdate(item);

    };

    const handleDetailsView = (reservation) => {
      state.selectedReservation = reservation;
      state.showDetails = true;
    };

    return {
      state,
      getBadgeClass,
      dateFormat,
      updateReservation,
      handleStatusUpdateAction,
      handleDetailsView,
    };
  }
};
</script>

<style lang="scss" scoped>
.reservation-header {
  position: sticky;
  top: 0;
  z-index: 1;
  color: black;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: #ffffffb8;
    position: absolute;
    backdrop-filter: blur(3px);
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.selected-bg {
  transition: background-color 50ms;
  background-color: #eaeaea !important;
}

.reservation-contents {
  transition: background-color 500ms;

  &:hover {
    @extend .selected-bg;
  }
}

.min-width110 {
  min-width: 110px;
}

</style>
