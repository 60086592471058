<template>
  <ModalComp
    title-text="Order Details"
    :show="modelValue"
    @hide="toggleModal(false)"
    max-width="800px"
    :show-action-button="false"
    title-icon-class="me-1 icon-assignment"
  >
    <div
      v-if="state.orderData?.id"
      class="order-actions mt-2"
    >
      <!--email send error-->
      <div
        v-if="!state.orderData.last_email_send_status"
        class="col-12"
      >
        <Message severity="error" :closable="false">
          Last email sent to this orders owner was unsuccessful.
        </Message>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="order-details">
            <div class="container">
              <div class="row" v-if="state.orderData">

                <!--User, Order & address info-->
                <div class="col-12">
                  <div class="fs25">
                    <i class="icon-mail me-2" />
                    <a :href="`mailto:${state.orderData.customer_email}`" class="text-secondary">
                      {{ state.orderData.customer_email }}
                    </a>
                  </div>
                  <div class="fs40">
                    {{ state.orderData.customer_name }}
                  </div>
                  <div
                    class="fs20"
                    v-if="state.orderData.type === 'collection'"
                  >
                    Collect From Store -
                    {{ state.orderData.requested_time_is_asap ? 'ASAP' : formatRequestedDate(state.orderData.requested_delivery_time) }}
                  </div>
                  <div
                    v-else
                    class="fs20"
                  >
                    Delivery -
                    {{ state.orderData.requested_time_is_asap ? 'ASAP' : formatRequestedDate(state.orderData.requested_delivery_time) }}<br />
                    {{ state.orderData.house_no }} {{ state.orderData.street_name }} <br />
                    {{ state.orderData.county }} {{ state.orderData.postcode }} <br />
                    {{ state.orderData.city }}
                  </div>
                  <div class="fs20">
                    <span class="me-2 fw6">Contact</span>
                    <a :href="`tel:${state.orderData.customer_phone}`" class="text-secondary">
                      {{ state.orderData.customer_phone }}
                    </a>
                  </div>
                  <div class="fs20">
                    <span class="me-2 fw6">Order placed time</span>
                    {{ formatOrderedDate(state.orderData.confirmation_time) }}
                  </div>
                </div>

                <!--items details-->
                <div class="col-12">
                  <div class="item-summery fs18 text-black">
                    <!--items list-->
                    <div
                      class=""
                      v-for="(items, typeName) in state.orderItems"
                      :key="typeName"
                    >
                      <div class="border border-grey border-1 my-3" />
                      <BadgeComp
                        :font-size="18"
                      >
                        {{ typeName }}
                      </BadgeComp>

                      <div
                        class="item-summery-content"
                        v-for="(item, index) in items"
                        :key="`item${index}`"
                      >
                        <div class="content">
                          <p class="ordered-item">
                            <span class="text-danger">{{ item.qty }} x </span>
                            {{ item.name }}
                          </p>
                          <p class="ordered-item-price">{{ currency((item.price * item.qty)) }}</p>
                        </div>
                        <div
                          class="content text-info"
                          v-for="(addon, idx) in item.addons"
                          :key="`addon${index}_${idx}`"
                        >
                          <p class="ordered-item addon-name">{{ `${addon.name}` }}</p>
                          <p class="ordered-item-price">{{ currency((addon.price * item.qty)) }}</p>
                        </div>
                      </div>

                    </div>

                    <!--set menus list-->
                    <div
                      v-if="state.orderData.set_menus"
                    >
                      <BadgeComp
                        :font-size="18"
                        type="se"
                      >
                        Set Menus
                      </BadgeComp>
                      <div
                        class="item-summery-content"
                        v-for="(setMenu, index) in state.orderData.set_menus"
                        :key="`setMenu${index}`"
                      >
                        <div class="content">
                          <p class="ordered-item fw-bold text-success">
                            <span class="text-dark">{{ setMenu.qty }} x </span>
                            {{ setMenu.name }}
                          </p>
                          <p class="ordered-item-price fw-bold">
                            <span class="text-muted" title="Original SetMenu price">
                              {{ currency((setMenu.menu_price * setMenu.qty)) }}/
                            </span>
                            <span title="Total SetMenu price with extra items">
                              {{ currency((setMenu.price * setMenu.qty)) }}
                            </span>
                          </p>
                        </div>
                        <div
                          v-for="(setMenuItem, idx) in setMenu.items"
                          :key="`setMenuItem${index}_${idx}`"
                        >
                          <div class="content">
                            <p class="ordered-item addon-name">{{ `${setMenuItem.name}` }}</p>
                            <p class="ordered-item-price">{{ currency((setMenuItem.price * setMenu.qty)) }}</p>
                          </div>

                          <div
                            class="content text-info"
                            v-for="(setMenuItemAddon, idx) in setMenuItem.addons"
                            :key="`setMenuItemAddon${index}_${idx}`"
                          >
                            <p class="ordered-item addon-name">{{ `${setMenuItemAddon.name}` }}</p>
                            <p class="ordered-item-price">{{ currency(setMenuItemAddon.price * setMenu.qty) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="border border-grey border-1 my-3" />

                </div>

                <!--payment summery-->
                <div class="col-10 offset-1">
                  <table class="table payment-table border border-4 rounded mt-2">
                    <tbody>
                    <tr class="d-flex pt-2">
                      <td class="payment-key col-6">Items </td>
                      <td class="payment-val col-6 text-danger">{{ currency(state.itemsTotal) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Addons</td>
                      <td class="payment-val col-6 text-danger">{{ currency(state.addonsTotal) }}</td>
                    </tr>
                    <tr
                      v-if="state.orderData.set_menus"
                      class="d-flex"
                    >
                      <td class="payment-key col-6">Set Menus</td>
                      <td class="payment-val col-6 text-danger">{{ currency(state.setMenusTotal) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Delivery Charge</td>
                      <td class="payment-val col-6 text-danger">{{ currency(state.orderData.delivery_amount) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Subtotal</td>
                      <td class="payment-val col-6 text-danger">{{ currency(getSubTotal()) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Discount</td>
                      <td class="payment-val col-6 text-success">{{ currency(state.orderData.discount_amount) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Coupon</td>
                      <td class="payment-val col-6 text-success">{{ currency(state.orderData.coupon_amount) }}</td>
                    </tr>
                    <tr class="d-flex">
                      <td class="payment-key col-6">Points</td>
                      <td class="payment-val col-6 text-success">{{ `${state.orderData['points_used']} (${currencySymbol}${0})` }}</td>
                    </tr>
                    <tr class="d-flex pb-2">
                      <td class="payment-key col-6 text-capitalize">{{ state.orderData.payment_type }}</td>
                      <td class="payment-val col-6 text-danger">{{ currency(state.orderData.total) }}</td>
                    </tr>


                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalComp>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue';
import { currency, currencySymbol, renderStatus } from '@/Mixins/appHelper';
import ModalComp from '@/components/Util/ModalComp';
import { orderStatusesNew, orderTypes, orderStatuses, formatDate, formatISODate } from '@/utils/Helper';
import Message from 'primevue/message';
import { collect } from 'collect.js';
import BadgeComp from '@/components/Util/BadgeComp';
import { APP_TIMEZONE } from '@/utils/constants';

export default defineComponent({
  name: 'OrderActionSimple',
  emits: ['update:modelValue'],
  components: { BadgeComp, ModalComp, Message },
  props: {
    modelValue: { // v-modal
      type: Boolean
    },
    order: {
      type: Object
    }
  },

  setup (props, { emit }) {

    const itemTypes = [
      'Starter',
      'Main Dish',
      'Side Dish',
      'Rice',
      'Bread',
      'Drinks',
      'Deserts',
      'Others'
    ];

    const state = reactive({
      modalShown: false,

      itemsTotal: 0,
      addonsTotal: 0,
      setMenusTotal: 0,
      orderData: props.order,
      orderItems: computed(() => {
        if (!props.order?.items) return {};
        return getOrderItemsByType(props.order.items);
      }),
    });

    function getOrderItemsByType (orderItems) {

      const items = collect(orderItems)
        .sortBy((item) => itemTypes.findIndex(typeName => item.type === typeName))
        .groupBy('type')
        .map(itm => itm.all())
        .all()
      ;

      return items;
    }

    const getSubTotal = () => {

      let itemsTotalCount = 0;
      let addonsTotalCount = 0;

      const setMenusTotal = state.orderData.set_menus ? state.orderData.set_menus.reduce((acc, setMenu) => {
        return acc + (setMenu.price * setMenu.qty);
      }, 0) : 0;

      // get items total
      const total = state.orderData.items ? state.orderData.items
        .reduce((n, item) => {

          const itemsTotal = item.price * item.qty;

          const addonsTotal = item.addons ? item.addons.reduce((total, addon) => {
            return total + (addon.price * item.qty);
          }, 0) : 0;

          // set totals separately
          itemsTotalCount += itemsTotal;
          addonsTotalCount += addonsTotal;

          return n + itemsTotal + addonsTotal;

        }, 0) : 0
      ;

      state.itemsTotal = itemsTotalCount;
      state.addonsTotal = addonsTotalCount;

      // get set menus total
      state.setMenusTotal = setMenusTotal;

      return total + setMenusTotal;

    };

    const getItemsCount = () => {
      return state.orderData.items.length + state.orderData.set_menus.length;
    };

    const toggleModal = (showHide) => {
      state.modalShown = showHide;

      if (!showHide) {
        emit('update:modelValue', showHide);
      }
    };

    watch(() => props.order, (nv) => {
      state.orderData = nv;
    });

    // map pops value to state
    watch(() => props.modelValue, (nv) => {
      state.modalShown = nv;
    });

    // track modal close
    watch(() => state.modalShown, (nv) => {
      toggleModal(nv);
    });

    const getOrderStatus = (order) => {

      const orderStatus = (order.type === orderTypes.collection) && (order.status === orderStatuses.delivered)
        ? orderStatusesNew.collected
        : orderStatusesNew[order.status]
      ;

      return renderStatus(orderStatus);
    };

    const formatRequestedDate = (dateString) => {
      const format = 'hh:mm a - do MMM 2022';
      return formatISODate(dateString, format, APP_TIMEZONE);
    };

    const formatOrderedDate = (dateString) => {
      const format = 'iii, do MMM, hh:mm a';
      return formatDate(dateString, format);
    };

    return {
      state,
      currency,
      getSubTotal,
      getItemsCount,
      toggleModal,
      formatRequestedDate,
      formatOrderedDate,
      currencySymbol,
      renderStatus,
      getOrderStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
.billing-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-table {
  border-radius: 10px;

  tr {
    td {
      border: 0;
      padding: 0 1rem;
    }
  }

  .payment-key {
    text-align: end;
    font-size: 22px;
    font-weight: 600;
    position: relative;

    &:after {
      content: ':';
      position: absolute;
      right: -2px;
      font-weight: 700;
    }
  }
  .payment-val {
    font-size: 24px;
    font-weight: 600;
    text-align: start;
  }
}


.order-actions {
  .user-details {
    font-size: 15px;
  }

}

.key {
  background-color: #f8f8f8;
}


.val {
  font-weight: 600;

  .help {
    color: darkgrey;
    font-size: 13px;
    font-style: italic;
  }
}

.table .divider {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.item-summery-content {
  font-size: 26px;
  font-weight: 600;

  .content {
    margin-bottom: 0;

    .addon-name {
      margin-left: 23px !important;
    }
  }
}

.item-summery {
  row-gap: 8px;
  font-size: 15px;
}

</style>
