<template>
  <div class="social-tile h-165">
    <router-link :to="link" class="card-link">
      <div class="social-icon shadow" :class="iconBg">
        <i :class="icon"></i>
      </div>
      <div>{{ title }}</div>
      <h2>{{ content }}</h2>
    </router-link>
  </div>
</template>
<script>

import { defineComponent } from 'vue';
export default defineComponent({

  name: 'tile-card',
  props: {
    iconBg: {
      type: String,
      required: false,
      default: 'bg-info'
    },
    icon: {
      required: false,
      type: String,
    },
    title: {
      type: String,
      required: false
    },
    content: {
      required: false
    },
    link: {
      type: String,
      default: ''
    }
  }
})
</script>

<style scoped lang="scss">
.card-link {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover, &:active {
    color: black;
  }
}
</style>
