<template>
  <div class="card">
    <div class="card-header card-header-with-toggle-btn">
      <div class="card-title">
        Reservations
        <small v-if="state.morePendingReservationCount" class="text-muted">
          (+{{ state.morePendingReservationCount }})
        </small>
      </div>
      <div class="d-flex align-items-center">
        <span class="me-2 fs13 hidden-sm-and-down">Show All Incomplete</span>
        <i
          class="icon-resize-full-screen full-screen-btn"
          @click="state.showLatestReservationPopUp = !state.showLatestReservationPopUp"
        />
      </div>

    </div>
    <div class="card-body">
      <NoOrder
        v-if="!state.reservations"
        title="No Pending Reservations"
        text="You have Confirmed all pending Reservation"
      />

      <NewReservationsList
        :reservations="state.reservations"
      />

    </div>
  </div>

  <LatestReservationsPopUp
    v-model="state.showLatestReservationPopUp"
  />

</template>

<script>
import NoOrder from '@/components/Util/card/noOrder';
import { currency, dateFormat, getBadgeClass } from '@/Mixins/appHelper';
import { computed, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import LatestOrdersPopUp from '@/components/Dashboard/Order/LatestOrdersPopUp';
import { reservationStatuses } from '@/utils/Helper';
import { collect } from 'collect.js';
import NewReservationsList from '@/components/Dashboard/Reservation/NewReservationsList';
import LatestReservationsPopUp from '@/components/Dashboard/Reservation/LatestReservationsPopUp';

export default {
  name: 'LatestPendingReservations',
  components: { LatestReservationsPopUp, NewReservationsList, LatestOrdersPopUp, NoOrder },
  emits: ['orderUpdated', 'update:showPopup'],
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {
    const store = useStore();

    const state = reactive({
      showLatestReservationPopUp: false,
      reservations: [],
      reservationStatuses: [],
      morePendingReservationCount: 0,
    });

    const updateLocalState = () => {

      const allPendingReservations =
        computed(() => {
          const allIncompleteReservations = store.getters['dashboard/reservations'];

          // only pending reservations
          return allIncompleteReservations.filter(order => order.status === reservationStatuses.pending);
        });

      const pendingReservationsToDisplay = computed(() => {
        return collect(allPendingReservations.value).take(10).toArray();
      });

      state.morePendingReservationCount = (allPendingReservations.value.length - pendingReservationsToDisplay.value.length);
      state.reservations = pendingReservationsToDisplay;
      state.reservationStatuses = computed(() => store.getters['dashboard/reservationStatuses']);
    };

    watch(() => props.showPopup, (nv) => {
      state.showLatestReservationPopUp = nv;
    });

    watch(() => state.showLatestReservationPopUp, (nv) => {
      emit('update:showPopup', nv);
    });

    onMounted(() => {
      updateLocalState();
    });

    return {
      state,
      currency,
      dateFormat,
      getBadgeClass,
    };
  }
};
</script>

<style lang="scss" scoped>
.full-screen-btn {
  font-size: 30px !important;
  padding: 5px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  cursor: pointer;
}
</style>
