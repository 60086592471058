<template>
  <ModalComp
    :show="modelValue"
    title-icon-class="icon-receipt"
    title-text="Reservations that Need to Take Action"
    custom-class="w-100 h-100"
    max-width="100%"
    max-height="100%"
    :show-action-button="false"
    @hide="closeModal"
  >

    <NewReservationsList
      :reservations="state.incompleteReservations"
    />

  </ModalComp>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import ModalComp from '@/components/Util/ModalComp';
import { useStore } from 'vuex';
import { reservationStatuses } from '@/utils/Helper';
import NewReservationsList from '@/components/Dashboard/Reservation/NewReservationsList';

export default {
  name: 'LatestReservationsPopUp',
  components: { NewReservationsList, ModalComp },
  emits: ['update:modelValue', 'reservationUpdated'],
  props: {
    modelValue: { // use with v-model for show hide
      type: Boolean
    },
  },

  setup (props, { emit }) {

    const store = useStore();

    const state = reactive({
      incompleteReservations: computed(() => {
        const reservations = store.getters['dashboard/reservations'];

        // exclude  confirmed & cancelled reservations
        const allowedStatuses = [reservationStatuses.pending];
        return reservations.filter(reservation => allowedStatuses.includes(reservation.status));
      }),
    });

    const closeModal = (value) => {

      // update only when modal closes
      if (!value) {
        emit('update:modelValue', value);
      }
    };

    watch(() => props.modelValue, (nv) => {
      closeModal(nv);
    });

    return {
      state,
      closeModal,
    };
  }
};
</script>

<style scoped>

</style>
