<template>
  <div class="dashboard" v-loading="loading">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <TileCard
          :content="latestOrdersCount"
          icon="icon-assignment"
          icon-bg="bg-danger"
          title="New Orders"
          @click="showOrdersPopup = true"
        />
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <TileCard
          :content="stats.orderCount"
          icon="icon-shopping-bag1"
          icon-bg="bg-success"
          title="Todays Orders"
          :link="routeMap.order.path"
        />

      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <TileCard
          :content="stats.offerCount"
          icon="icon-redeem"
          icon-bg="bg-warning"
          title="Offers"
          :link="routeMap.offer.path"
        />

      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-6">

        <TileCard
          :content="latestReservationsCount"
          icon="icon-receipt"
          icon-bg="bg-info"
          title="New Reservations"
          @click="showReservationsPopup = true"
        />

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <!-- Card start -->
        <LatestPendingOrders
          v-model:show-popup="showOrdersPopup"
        />
        <!-- Card end -->
      </div>
    </div>

    <!--  Activities and Other  -->
    <div class="row">
      <!-- Reservations -->
      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
        <LatestPendingReservations
          v-model:show-popup="showReservationsPopup"
        />
      </div>

      <!--  Activities    -->
      <div class="col-lg-5 col-md-5 col-sm-5 col-12">
        <DashboardActivities />
      </div>
      <!--  /Activities    -->

      <!--  /Activities and Other  -->
    </div>

  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import TileCard from '@/components/Util/card/tileCard';
import NoOrder from '@/components/Util/card/noOrder';
import { currency, dateFormat, getBadgeClass, renderStatus, userAvatar } from '@/Mixins/appHelper';
import DashboardActivities from '@/components/Dashboard/DashboardActivities';
import LatestPendingOrders from '@/components/Dashboard/Order/LatestPendingOrders';
import LatestPendingReservations from '@/components/Dashboard/Reservation/LatestPendingReservations';

export default defineComponent({
  name: 'Dashboard',
  components: { LatestPendingReservations, LatestPendingOrders, DashboardActivities, NoOrder, TileCard },

  setup () {
    const store = useStore();

    const state = reactive({
      loading: true,
      latestOrdersCount: computed(() => store.getters['app/getNewOrdersCount']),
      latestReservationsCount: computed(() => store.getters['app/getNewReservationCount']),
      stats: {},
      orderStatuses: [],
      reservationStatuses: [],
      reservations: [],
      orders: [],

      showOrdersPopup: false,
      showReservationsPopup: false,

      selectedItem: {},
      statusOptions: [],
      showStatusAction: false,
      statusUpdateActionType: '',

      statusActionTitle: 'Update Order Status',

    });

    const updateLocalState = () => {
      state.loading = computed(() => store.state.dashboard.loading);
      state.stats = computed(() => store.getters['dashboard/stats']);
      state.orders = computed(() => store.getters['dashboard/orders']);
      state.orderStatuses = computed(() => store.getters['dashboard/orderStatuses']);
      state.reservations = computed(() => store.getters['dashboard/reservations']);
      state.reservationStatuses = computed(() => store.getters['dashboard/reservationStatuses']);
    };

    onMounted(async () => {
      await store.dispatch('dashboard/getDashboardContent');
      updateLocalState();

      // reset new order & reservation counts
      store.dispatch('app/resetNewOrdersCount');
      store.dispatch('app/resetNewReservationCount');
      // stop new order sound
      store.dispatch('app/stopNewOrderSound');

    });

    return {
      ...toRefs(state),
      renderStatus,
      dateFormat,
      currency,
      getBadgeClass,
      userAvatar,
    };
  }
});
</script>

<style lang="scss">
</style>
