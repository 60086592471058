<template>
  <span
    class="badge cursor-pointer timer-custom fs15"
    :class="state.time.hours > 1 ? 'status-default' : 'status-warning'"
  >
    <i class="icon-timer"></i>
    <span class="timer-text"> {{ state.formatTime }}</span>
  </span>
</template>

<script>
import { defineComponent, reactive, onMounted, onBeforeUnmount, computed } from 'vue';
import { getRemainingTime } from '@/utils/Helper';
export default defineComponent({
  name: 'OrderTimer',
  props: {
    date: {
      type: String,
    },
    order: {
      type: Object
    }
  },

  setup (props) {

    const state = reactive({
      time: {},
      formatTime: null,
      showReminingTime: computed(() => {
        const ignoredStatus = ['cancelled', 'delivered']
        const currentStatus = ignoredStatus.includes(props.order.status)
        return !currentStatus;
      })
    });

    let interval = null;

    const updateTime = () => {

      if (!state.showReminingTime) return;

      const remainingTime = getRemainingTime(props.date);
      state.time = remainingTime;

      const format = state.time.hours
        ? `${state.time.hours} hr ${state.time.minutes} min`
        : `${state.time.minutes} min ${state.time.seconds} sec`
      ;
      state.formatTime = format;

    }


    onMounted(() => {

      if (!state.showReminingTime) return;

      interval = setInterval(() => {
        updateTime();
      }, 2000)
    });

    onBeforeUnmount(() => {
      clearInterval(interval)
    })

    return {
      state,
      getRemainingTime,
    };
  },

});
</script>

<style scoped>

.timer-custom{
  padding: 3px 14px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
}

.status-warning{
  background: #1c1a1a;
}

.status-default{
  background: #877600;
}

.badge i{
  margin-right: 5px;
}
</style>
