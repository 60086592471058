<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title text-center text-info text-uppercase">{{ title }}</div>
    </div>
    <div class="card-body">

        <div class="revenue-container">
          <p v-if="icon"> <i :class="icon" class="fa-8x"></i> </p>
          <div v-if="!icon" class="revenue-graph">
            <img :src="medal" class="img-fluid" alt="Medal">
          </div>
          <div class="revenue-details">
            <p class="h4">{{ text }}</p>
          </div>
        </div>

    </div>
  </div>
</template>
<script>

export default {
  name: 'no-order',
  props: {

    title: {
      type: String,
      default: 'no order in queue'
    },

    text: {
      type: String,
      default: 'You have served all Orders.'
    },

    icon: {
      required: false,
      type: String
    }
  },

  data: () => ({
    medal: require('@/assets/img/medal.svg').default
  }),

}
</script>
